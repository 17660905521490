import React, { useState } from 'react';

const Plus = ({ size = 10, borderColor, plusColor }) => {
  return (
    <div
      style={{
        display: 'flex',
        border: `solid 0.5px ${borderColor}`,
        borderRadius: 100,
        borderWidth: 0.5,
        width: size,
        height: size,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: size * 0.55,
          height: 0.8,
          backgroundColor: plusColor,
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: size * 0.55,
          height: 0.8,
          backgroundColor: plusColor,
          transform: 'rotate(90deg)',
        }}
      />
    </div>
  );
};

const Minus = ({ size = 10, borderColor, plusColor }) => {
  return (
    <div
      style={{
        display: 'flex',
        border: `solid 0.5px ${borderColor}`,
        borderRadius: 100,
        borderWidth: 0.5,
        width: size,
        height: size,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: size * 0.55,
          height: 0.8,
          backgroundColor: plusColor,
        }}
      />
    </div>
  );
};

const MainCategoryItem2 = (props) => {
  const { item, selectedCategoryId1Depth, subCategoryRender } = props;
  const [isOpen, setIsOpen] = useState(item.id === selectedCategoryId1Depth);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          borderBottomWidth: 0.5,
          borderBottomColor: 'var(--lightDarkGray)',
          height: 40,
          alignItems: 'center',
          paddingLeft: 12,
          paddingRight: 12,
          justifyContent: 'space-between',
          backgroundColor: '#ffffff',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.stopPropagation();
          toggleOpen();
          // if (index === 0) {
          //   setCategory1Depth(index);
          //   dispatch(setSelectedCategoryIndex0Depth(category0Depth));
          //   dispatch(setSelectedCategoryIndex1Depth(0));
          //   toggleIsBrandFilter();
          // } else {
          // dispatch(setSelectedCategoryIndex0Depth(categoryIndex0Depth));
          // toggleOpen();
          // }
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              width: 20,
              height: 20,
              marginRight: 5,
              objectFit: 'contain',
            }}
            src={item.imgUrl}
          />
          <div
            style={{
              fontSize: 12,
              color: isOpen ? 'var(--emerald)' : 'var(--black2)',
              fontWeight: isOpen ? 700 : 400,
            }}
          >
            {item.name}
          </div>
        </div>
        {!isOpen ? (
          <Plus size={10} borderColor={'#c4c4c4'} plusColor={'#c4c4c4'} />
        ) : (
          <Minus size={10} borderColor={'#c4c4c4'} plusColor={'#c4c4c4'} />
        )}
      </div>
      {isOpen && subCategoryRender(item)}
    </div>
  );
};
export default MainCategoryItem2;
