import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootStore';
import * as styles from '../../styles/productResult/productResultCategoryMenu.module.css';

interface Props {
  onFinish?: () => void;
  categoryColor: string;
  setSubCategoryId?: (id: number) => void;
  setSubCategoryId2?: (id: number) => void;
  selectedCategoryIndex1Depth: number;
  selectedCategoryIndex2Depth: number;
}

const ProductResultCateogryMenu = (props: Props) => {
  const {
    onFinish,
    categoryColor,
    selectedCategoryIndex0Depth,
    selectedCategoryIndex1Depth,

    selectedCategoryIndex2Depth,
  } = props;
  const appType = useSelector((state: RootState) => state.appType);
  const categoryData = useSelector((state: RootState) => state.categoryData);
  const [categoryIndex0Depth, setCategoryIndex0Depth] = useState(selectedCategoryIndex0Depth);
  const [categoryIndex1Depth, setCategoryIndex1Depth] = useState(selectedCategoryIndex1Depth);

  // for horizontal drag
  const containerRef = useRef<HTMLDivElement>(null);
  /** 요소를 드래그하고 있는가? */
  const [isDragging, setIsDragging] = useState<boolean>(false);
  /** 드래그 시작 시점의 X축 좌표값 */
  const [startX, setStartX] = useState<number>(0);
  /** 드래그 시작 시점의 스크롤 포지션이 포함된 X축 좌표값 */
  const [totalX, setTotalX] = useState<number>(0);

  const onDragStart = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    preventUnexpectedEffects(event);
    setIsDragging(true);
    const x = event.clientX;
    setStartX(x);
    if (containerRef.current && 'scrollLeft' in containerRef.current) {
      setTotalX(x + containerRef.current.scrollLeft);
    }
  };

  const onTouchStart = (event: React.TouchEvent<HTMLElement>) => {
    preventUnexpectedEffects(event);
    setIsDragging(true);
    const x = event.touches[0].clientX;
    setStartX(x);
    if (containerRef.current && 'scrollLeft' in containerRef.current) {
      setTotalX(x + containerRef.current.scrollLeft);
    }
  };

  const onDragMove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!isDragging) return;

    throttle(() => {
      preventUnexpectedEffects(e);
      const scrollLeft = totalX - e.clientX;

      if (containerRef.current && 'scrollLeft' in containerRef.current) {
        // 스크롤 발생
        containerRef.current.scrollLeft = scrollLeft;
      }
    }, 100);
  };
  const onTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    if (!isDragging) return;

    throttle(() => {
      preventUnexpectedEffects(e);
      const scrollLeft = totalX - e.touches[0].clientX;

      if (containerRef.current && 'scrollLeft' in containerRef.current) {
        // 스크롤 발생
        containerRef.current.scrollLeft = scrollLeft;
      }
    }, 100);
  };
  const onDragEnd = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!isDragging) return;
    if (!containerRef.current) return;

    setIsDragging(false);

    const endX = e.clientX;
    const childNodes = containerRef.current?.childNodes || [];
    const dragDiff = Math.abs(startX - endX);
    if (dragDiff > 10) {
      childNodes.forEach((child) => {
        child.addEventListener('click', () => preventUnexpectedEffects);
      });
    } else {
      childNodes.forEach((child) => {
        child.removeEventListener('click', () => preventUnexpectedEffects);
      });
    }
  };
  const onTouchEnd = (e: React.TouchEvent<HTMLElement>) => {
    if (!isDragging) return;
    if (!containerRef.current) return;

    setIsDragging(false);

    const endX = e.touches[0].clientX;
    const childNodes = containerRef.current?.childNodes || [];
    const dragDiff = Math.abs(startX - endX);
    if (dragDiff > 10) {
      childNodes.forEach((child) => {
        child.addEventListener('click', () => preventUnexpectedEffects);
      });
    } else {
      childNodes.forEach((child) => {
        child.removeEventListener('click', () => preventUnexpectedEffects);
      });
    }
  };

  const preventUnexpectedEffects = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent> | React.TouchEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const throttle = (func: () => void, delay: number) => {
    let timer;
    if (!timer) {
      timer = setTimeout(function () {
        timer = null;
        func();
      }, delay);
    }
  };

  return (
    <div className={styles.backgroundContainer}>
      {appType === 'mom' && (
        <div
          style={{
            display: 'flex',
            height: 40,
            backgroundColor: '#ffffff',
          }}
        >
          {categoryData.map((data, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: categoryIndex0Depth === index ? 'var(--emerald)' : '#f3f3f3',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setCategoryIndex0Depth(index);
                  setCategoryIndex1Depth(0);
                }}
              >
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 700,
                    color: categoryIndex0Depth === index ? '#ffffff' : 'var(--lightDarkGray)',
                  }}
                >
                  {categoryData[index].name}
                </span>
              </div>
            );
          })}
        </div>
      )}

      {/* 가로 스크롤&드래그 가능한 container */}
      <div
        ref={containerRef}
        onMouseDown={onDragStart}
        onTouchStart={onTouchStart}
        onMouseMove={onDragMove}
        onTouchMove={onTouchMove}
        onMouseUp={onDragEnd}
        onTouchEnd={onTouchEnd}
        onMouseLeave={onDragEnd}
        className={styles.mainCategoryContainer}
      >
        {categoryData[categoryIndex0Depth].children.map((item, index) => {
          const isSelected = categoryIndex1Depth === index;
          return (
            <div
              key={item.id}
              className={styles.mainCategoryItem}
              style={{
                borderBottom: `solid ${isSelected ? 3 : 0}px ${categoryColor}`,
              }}
              onClick={(e) => {
                e.stopPropagation();
                //   scrollRef.current?.scrollToIndex({ index, animated: true });
                setCategoryIndex1Depth(index);
              }}
            >
              <span
                style={{
                  fontSize: 13,
                  color: isSelected ? categoryColor : '#000000',
                }}
              >
                {item.name}
              </span>
            </div>
          );
        })}
      </div>

      <div className={styles.subCategoryContainer}>
        {categoryData[categoryIndex0Depth].children[categoryIndex1Depth]?.children.map((item, index) => {
          const isSelected = selectedCategoryIndex2Depth === index && categoryIndex1Depth === selectedCategoryIndex1Depth;
          return (
            <div
              key={item.id}
              className={styles.subCategoryItem}
              onClick={(e) => {
                e.stopPropagation();
                onFinish &&
                  onFinish(
                    categoryData[categoryIndex0Depth].id,
                    categoryData[categoryIndex0Depth].children[categoryIndex1Depth].id,
                    item.id,
                  );
              }}
            >
              <span
                style={{
                  fontSize: 12,
                  color: isSelected ? categoryColor : '#898989',
                }}
              >
                {item.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductResultCateogryMenu;
