import React, { useState } from 'react';
import * as styles from '../../styles/productResult/categoryGradeSwitch.module.css';
import { images } from '../../data';
import ModalPopup from '../ModalPopup';
import { navigate } from 'gatsby-link';
import { getImageModal } from '../../apis/commom';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootStore';
import useMomguideProductGrades from '../../hooks/useMomguideGrade';

interface Props {
  containerStyle?: any;
  isGrade?: boolean;
  onPressGradeFilter: (grade: string) => void;
  filterGrade: string[];
}

const renderItem = (item: any, isGrade: boolean, onPressGradeFilter: (grade: string) => void, filterGrade: string[]) => {
  const selected = filterGrade.findIndex((grade) => grade === item.grade) !== -1;
  const isLocked = true; //!isGrade && !["D", "X"].includes(item.grade)

  const toggle = () => {
    if (isLocked) {
      //   alert(
      //     'TIP!',
      //     '제품 리뷰를 단 1개만 작성하시면\n모든 제품을 등급으로 나눠볼 수 있어요:)\n리뷰를 쓰러 가시겠습니까?',
      //     [
      //       {
      //         text: '아니요',
      //         onPress: () => {
      //           amplitudeLog('(리뷰x)등급필터클릭', { 확인: '아니요' });
      //         },
      //       },
      //       {
      //         text: '네',
      //         onPress: () => {
      //           amplitudeLog('(리뷰x)등급필터클릭', { 확인: '네' });
      //           navigation.push('ReviewWrite');
      //         },
      //       },
      //     ],
      //   );
      //   return;
      // }
      onPressGradeFilter(item.grade);
    }
  };
  return (
    <div style={{ height: 47.5, paddingTop: 2.5, position: 'relative' }} onClick={toggle} key={'' + item.grade}>
      <img
        style={{
          opacity: selected && !isLocked ? 1 : 0.3,
          imageRendering: '-webkit-optimize-contrast',
          width: 36.85,
          objectFit: 'contain',
        }}
        src={item.image}
      />
      {isLocked && <img src={images.lock} style={{ position: 'absolute', right: 0.5 }} />}
    </div>
  );
};

const CategoryGradeSwitch = (props: Props) => {
  const { isGrade = true, onPressGradeFilter, filterGrade } = props;
  const appType = useSelector((state: RootState) => state.appType);
  const momguideProductGrades = useMomguideProductGrades();
  const [isMomguideDetailInfo, setIsMomguideDetailInfo] = useState(false);
  const [modalData, setModalData] = useState<{}>();
  // const togglePopup = () => dispatch(popupVisible());//제품성분등급 팝업

  const moveGradeExplain = () => {
    // if(!isGradePopupVisible){
    //   amplitudeLog('제품 검색 결과 - 제품 성분등급 확인하기 - 자세히 알아보기');
    // }
    // amplitudeLog("제품 검색 결과 - 제품 성분등급 확인하기 - 자세히 알아보기")
    // navigate("/productGradeExplain")
    // setIsGradePopupVisible(!isGradePopupVisible)

    setIsMomguideDetailInfo(true);
    getModalData('grade');
  };

  const getModalData = async (displayedAt: 'grade') => {
    const res = await getImageModal(displayedAt);
    const otherData: {
      title?: string;
      titleColor?: `#${string}`;
      titleBackgroundColor?: `#${string}`;
    } = {};

    otherData.title = appType === 'mom' ? '맘가이드 등급' : '펫가이드 등급';
    otherData.titleColor = '#000000';
    otherData.titleBackgroundColor = '#ffffff';

    setModalData({ ...otherData, images: res.rows });
  };

  return (
    <div className={styles.container}>
      <div className={styles.switchView}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flexDirection: 'row', alignItems: 'center' }}>
            <span
              style={{
                fontWeight: 'bold',
                fontSize: 12,
                marginTop: 2,
                marginRight: 8,
                color: '#000000',
                cursor: 'pointer',
              }}
            >
              등급별 필터링
            </span>
          </div>
        </div>
        <div onClick={moveGradeExplain}>
          <span
            style={{
              fontSize: 11,
              marginTop: 2,
              color: 'var(--vividMint)',
              textDecorationLine: 'underline',
              cursor: 'pointer',
            }}
          >
            자세히 알아보기
          </span>
        </div>
      </div>
      <div className={styles.filterStyle}>
        {momguideProductGrades
          .filter((grade) => grade.grade !== 'V2') // V2는 등급필터 제외.
          .map((item) => renderItem(item, isGrade, onPressGradeFilter, filterGrade))}
      </div>
      <div className={styles.filterDescriptionBarContainer}>
        <div className={styles.filterDescriptionWrapper}>
          {/** 펫가이드에서도 같은 색상으로 보이게 하기 위해 색상값 고정 */}
          <span className={styles.filterDescription} style={{ color: '#88d8cf' }}>
            성분Good
          </span>
        </div>
        <div
          style={{
            width: '70%',
            height: 1,
            background: '#dadada',
          }}
        />
        <div className={styles.filterDescriptionWrapper}>
          <span className={styles.filterDescription} style={{ color: '#ff7272' }}>
            성분Bad
          </span>
        </div>
      </div>
      <ModalPopup
        isVisible={isMomguideDetailInfo}
        setIsVisible={setIsMomguideDetailInfo}
        title={modalData?.title}
        titleStyle={{ color: modalData?.titleColor }}
        backgroundColor={modalData?.titleBackgroundColor}
      >
        <div style={{ background: '#ffffff' }}>
          {modalData?.images?.map((item) => {
            return (
              <img
                style={{
                  width: '100%',
                  imageRendering: '-webkit-optimize-contrast',
                }}
                src={item.url}
              />
            );
          })}
        </div>
      </ModalPopup>
    </div>
  );
};

export default CategoryGradeSwitch;
