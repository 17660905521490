import { navigate } from 'gatsby-link';
import React, { useState } from 'react';
import MainCategoryItem2 from '../searchComp/MainCategoryItem2';
import { SubCategoryItem2 } from '../searchComp/SubCategoryItem2';
import * as styles from '../../styles/productResult/categorySelectorForBrand.module.css';

interface Props {
  style?: any;
  top: number;
  brandCategories: any;
  navigation: any;
  selectedCategoryIndex1Depth: number;
  selectedCategoryIndex2Depth?: number;
  toggleIsBrandFilter: () => any;
  searchBrand?: any;
}

const CategorySelectorForBrand = (props: Props) => {
  const {
    style,
    brandCategories,
    navigation,
    selectedCategoryIndex0Depth,
    selectedCategoryIndex1Depth,
    selectedCategoryIndex2Depth,
    selectedCategoryIndex3Depth,

    toggleIsBrandFilter,
    searchBrand,
  } = props;

  return (
    <div className={styles.container} style={style} onClick={toggleIsBrandFilter}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <div style={{ backgroundColor: 'transparent', width: '100%' }}>
          {brandCategories.map((item0, index) => {
            if (item0.id === 0) {
              return (
                <div
                  style={{
                    backgroundColor: 'var(--whiteGray2)',
                    display: 'flex',
                    height: 29,
                  }}
                  key={item0.id}
                >
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: 'var(--whiteGray2)',
                      padding: '2px 0 2px 0',
                      paddingLeft: 16,
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      navigate(`/productResult/brand,${item0.id}`, {
                        state: { brand: searchBrand },
                      });
                      // dispatch(setSelectedCategoryIndex0Depth(index))
                      toggleIsBrandFilter();
                    }}
                  >
                    <span
                      style={{
                        color: 'var(--darkGray)',
                        fontWeight: 700,
                        fontSize: 12,
                      }}
                    >
                      모든 카테고리 보기
                    </span>
                    <img
                      style={{
                        width: 11,
                        height: 11,
                        transform: 'rotate(180deg)',
                        marginLeft: 2,
                      }}
                      src="/images/arrow_left.svg"
                    />
                  </div>
                </div>
              );
            }

            return (
              <div style={{ backgroundColor: '#ffffff' }}>
                <div
                  style={{
                    height: 40,
                    justifyContent: 'space-between',
                    backgroundColor: selectedCategoryIndex0Depth === index ? 'var(--emerald)' : 'var(--whiteGray)',
                    paddingLeft: 14,
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={() => {
                    // setSelectedCategoryIndex0Depth(index);
                    navigate(`/productResult/brand,${item0.id}`, {
                      state: { brand: searchBrand },
                    });
                    toggleIsBrandFilter();
                  }}
                >
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: selectedCategoryIndex0Depth === index ? '#ffffff' : 'var(--black2)',
                    }}
                  >
                    {item0.name}
                  </span>
                  <div
                    style={{
                      backgroundColor: '#f3f3f3',
                      borderRadius: 30,
                      padding: '1px 4px',
                      justifyContent: 'center',
                      marginRight: 13,
                      display: 'flex',
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        fontWeight: selectedCategoryIndex0Depth === index ? 700 : 400,
                        color: selectedCategoryIndex0Depth === index ? 'var(--emerald)' : 'var(--lightDarkGray)',
                      }}
                    >
                      전체보기
                    </span>
                  </div>
                </div>

                {item0?.children.map((item1, index2) => {
                  const selectedCategoryId0Depth = brandCategories[selectedCategoryIndex0Depth]?.id;
                  const selectedCategoryId1Depth = brandCategories[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth]?.id;

                  const selectedCategoryId2Depth =
                    typeof selectedCategoryIndex2Depth === 'number' &&
                    brandCategories[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth]?.children?.[
                      selectedCategoryIndex2Depth
                    ]?.id;

                  const selectedCategoryId3Depth =
                    typeof selectedCategoryIndex2Depth === 'number' &&
                    typeof selectedCategoryIndex3Depth === 'number' &&
                    brandCategories[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth].children?.[
                      selectedCategoryIndex2Depth
                    ]?.children?.[selectedCategoryIndex3Depth]?.id;

                  return (
                    <MainCategoryItem2
                      key={item1.id}
                      categories={brandCategories}
                      item={item1}
                      categoryIndex0Depth={index}
                      categoryIndex1Depth={index2}
                      searchBrand={searchBrand}
                      onClose={toggleIsBrandFilter}
                      selectedCategoryId0Depth={selectedCategoryId0Depth}
                      selectedCategoryId1Depth={selectedCategoryId1Depth}
                      selectedCategoryId2Depth={selectedCategoryId2Depth}
                      selectedCategoryId3Depth={selectedCategoryId3Depth}
                      subCategoryRender={() => {
                        return item1.children?.map((data, index3) => {
                          return (
                            <div style={{ backgroundColor: '#ffffff' }} key={data.id}>
                              <SubCategoryItem2
                                item={data}
                                index={index3}
                                selectedCategoryId2Depth={selectedCategoryId2Depth}
                                selectedCategoryId3Depth={selectedCategoryId3Depth}
                                mainCategoryItem0={item0}
                                mainCategoryItem1={item1}
                                navigation={navigation}
                                onFinish={() => {
                                  toggleIsBrandFilter();
                                }}
                                searchBrand={searchBrand}
                              />
                            </div>
                          );
                        });
                      }}
                    />
                  );
                })}
              </div>
            );

            // return (
            //   <div
            //     style={{
            //       backgroundColor: "#ffffff",
            //       borderRight: "solid 0.5px #dadada",
            //     }}
            //     key={item.id}
            //   >
            //     <MainCategoryItem2
            //       item={item.children}
            //       index={index}
            //       categoryIndex={category1Depth}
            //       isSelected={category1Depth === index}
            //       onClick={() => {
            //         if (index === 0) {
            //           setCategory1Depth(index)
            //           // dispatch(setSelectedCategoryIndex1Depth(0));
            //           navigate(
            //             `/productResult/${
            //               (searchBrand ? "brand," : "") + item.id
            //             }`,
            //             {
            //               state: { brand: searchBrand },
            //             }
            //           )
            //           toggleIsBrandFilter()
            //         } else {
            //           setCategory1Depth(index)
            //         }
            //       }}
            //     />
            //   </div>
            // )
          })}
        </div>
        {/* <div style={{ flex: 1, backgroundColor: "transparent" }}>
          {brandCategories[category1Depth]?.children.map((item, index) => {
            return (
              <div style={{ backgroundColor: "#ffffff" }} key={item.id}>
                <SubCategoryItem
                  item={item}
                  index={index}
                  navigation={navigation}
                  onFinish={() => {
                    //   dispatch(setSelectedCategoryIndex1Depth(category1Depth));
                    toggleIsBrandFilter()
                  }}
                  searchBrand={searchBrand}
                  mainCategoryItem={brandCategories[category1Depth]}
                />
              </div>
            )
          })}
        </div> */}
      </div>
    </div>
  );
};

export default CategorySelectorForBrand;
