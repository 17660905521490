import { navigate } from 'gatsby-link';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useQueryParam, NumberParam } from 'use-query-params';
import { apiGetBrandCategory, apiRankingCategoriesV2 } from '../../apis/categoryApi';
import { apiProductSearchV3 } from '../../apis/productApi';

import AppContainerView from '../../components/AppContainerView';
import MobileHeader from '../../components/MobileHeader';
import MobileTemplate from '../../components/MobileTemplate';
import ProductResultCateogryMenu from '../../components/productResult/ProductResultCategoryMenu';
import { SearchComponent } from '../../components/productResult/SearchComponent';
import { categoryColorData, images } from '../../data';
import { showPopup, fetchGetCategory, RootState } from '../../state/rootStore';
import CategoryGradeSwich from '../../components/productResult/CategoryGradeSwitch';
import * as styles from '../../styles/productResult/productResult.module.css';
import SearchText from '../../components/productResult/SearchText';
import CategorySelectorForBrand from '../../components/productResult/CategorySelectorForBrand';
import ProductItem from '../../components/searchComp/ProductItem';
import { PageProps } from 'gatsby';
import LoadingComp from '../../components/Loading';
import { SEO } from 'src/components/seo';

interface LocationProps {
  brand: { id: number; fullName: string };
}
interface Props {
  //   navigation: NavigationScreenProp<NavigationParams>
  params: { id: number | string };
}

const getSearchCategory = (
  searchCategoryArray: any,
  selectedCategoryIndex0Depth: any,
  selectedCategoryIndex1Depth: any,
  selectedCategoryIndex2Depth: any,
  selectedCategoryIndex3Depth: any,
) => {
  if (selectedCategoryIndex3Depth !== undefined) {
    return searchCategoryArray[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth]?.children[selectedCategoryIndex2Depth!]
      ?.children[selectedCategoryIndex3Depth];
  }

  if (selectedCategoryIndex2Depth !== undefined) {
    return searchCategoryArray[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth]?.children[selectedCategoryIndex2Depth!];
  }

  if (selectedCategoryIndex1Depth !== undefined) {
    return searchCategoryArray[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth];
  }
  return searchCategoryArray[selectedCategoryIndex0Depth];
};

const productResult = ({ params, location, ...rest }: PageProps<Props, null, LocationProps>) => {
  const [categoryRankingIds, setCategoryRankingIds] = useState([]);

  const paramsObj = new URLSearchParams(location.search);
  // const [subCategoryId, setSubCategoryId] = useQueryParam('subCategoryId', NumberParam);
  const [subCategoryId, setSubCategoryId] = useState(paramsObj.get('subCategoryId'));
  // const [subCategoryId2, setSubCategoryId2] = useQueryParam('subCategoryId2', NumberParam);
  const [subCategoryId2, setSubCategoryId2] = useState(paramsObj.get('subCategoryId2'));
  const [searchBrand, setSearchBrand] = useState(location.state?.brand);
  const [brandCategories, setBrandCategories] = useState([]);
  const dispatch = useDispatch();
  const categotyId1and2 = params.id;

  const isBrand = categotyId1and2?.includes('brand');

  useEffect(() => {
    const init = async () => {
      if (searchBrand) {
        const res = await apiGetBrandCategory(searchBrand.id); //brand.id
        // if (res && res[0].id !== "all") {
        //   res.unshift({
        //     id: "all",
        //     imgUrl:
        //       "http://image.toast.com/aaaaayl/production/general-images/allview.png",
        //     name: "전체보기",
        //     children: [],
        //   })
        // }
        setBrandCategories(res);
      }
      const rankingCategoryRes = await apiRankingCategoriesV2();
      setCategoryRankingIds(rankingCategoryRes);
    };
    init();
  }, [searchBrand]);

  const onPressProduct = () => {};
  const keyword = undefined;

  const page = useRef(0);

  const categoryData = useSelector((state: RootState) => state.categoryData);
  const [selectedCategoryIndex0Depth, setSelectedCategoryIndex0Depth] = useState(0);
  const [selectedCategoryIndex1Depth, setSelectedCategoryIndex1Depth] = useState(0);
  const [selectedCategoryIndex2Depth, setSelectedCategoryIndex2Depth] = useState(0);
  const [selectedCategoryIndex3Depth, setSelectedCategoryIndex3Depth] = useState(0);

  const category2Depth =
    selectedCategoryIndex0Depth === undefined
      ? {}
      : categoryData?.[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth]?.children[selectedCategoryIndex2Depth];

  const searchCategoryArray = isBrand ? brandCategories : categoryData;
  // 찾을 카테고리 id.
  const searchCategory = getSearchCategory(
    searchCategoryArray,
    selectedCategoryIndex0Depth,
    selectedCategoryIndex1Depth,
    selectedCategoryIndex2Depth,
    selectedCategoryIndex3Depth,
  );

  const isRanking = !isBrand && categoryRankingIds.includes(searchCategory?.id);

  const ORDER_KEY = isRanking ? ['ranking', 'reviewCount', 'score', 'view', 'latest'] : ['reviewCount', 'score', 'view', 'latest'];
  const sortTitles = isRanking ? ['랭킹순', '리뷰 많은순', '별점순', '조회순', '최신순'] : ['리뷰 많은순', '별점순', '조회순', '최신순'];

  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState('');
  const [filterGrade, setFilterGrade] = useState<string[]>([]);
  const [order, setOrder] = useState(ORDER_KEY[0]);
  const [orderIndex, setOrderIndex] = useState(0);
  const [productCount, setProductCount] = useState<number>(0);

  const [isLoading, setLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const [isEOS, setIsEOS] = useState<boolean>(false);
  const [isGrade, setIsGrade] = useState<boolean>(false);
  const [isBrandFilter, setIsBrandFilter] = useState(false);
  const [isVisibleProductResultCateogryMenu, setIsVisibleProductResultCateogryMenu] = useState<boolean>(false);

  const category3Depth = cloneDeep(category2Depth?.children);

  if (category3Depth?.length % 3 !== 0) {
    for (let i = 0; i < category3Depth?.length % 3; i++) {
      category3Depth.push({ name: '' });
    }
  }

  const _reset = () => {
    setFilter('');
    setFilterGrade([]);
    setOrder(ORDER_KEY[0]);
    setOrderIndex(0);
    setIsEOS(false);
    page.current = 0;
  };

  useEffect(() => {
    const mainCategoryId0 = categotyId1and2?.includes('brand')
      ? parseInt(categotyId1and2.split(',')[1])
      : parseInt(categotyId1and2.split(',')[0]);

    const mainCategoryIndex0 = searchCategoryArray.findIndex((d) => d.id == mainCategoryId0);
    setSelectedCategoryIndex0Depth((prev: number) => mainCategoryIndex0);

    if (mainCategoryIndex0 !== -1) {
      const mainCategoryId1 = categotyId1and2?.includes('brand')
        ? parseInt(categotyId1and2.split(',')[2])
        : parseInt(categotyId1and2.split(',')[1]);

      const mainCategoryIndex1 = searchCategoryArray[mainCategoryIndex0]?.children.findIndex((d) => d.id == mainCategoryId1);
      if (mainCategoryIndex1 !== -1) {
        const subCategoryIndex = searchCategoryArray[mainCategoryIndex0]?.children?.[mainCategoryIndex1]?.children.findIndex(
          (d) => d.id == subCategoryId,
        );

        setSelectedCategoryIndex1Depth(mainCategoryIndex1);

        if (subCategoryIndex !== -1) {
          const subCategoryIndex2 = searchCategoryArray[mainCategoryIndex0]?.children?.[mainCategoryIndex1].children[
            subCategoryIndex
          ].children.findIndex((d) => d.id == subCategoryId2);
          setSelectedCategoryIndex2Depth(subCategoryIndex);

          if (subCategoryIndex2 !== -1) {
            setSelectedCategoryIndex3Depth(subCategoryIndex2);
          } else if (subCategoryId === subCategoryId2 || subCategoryId2 === undefined || subCategoryId2 == null) {
            setSelectedCategoryIndex3Depth(undefined);
          }
        }
      } else {
        setSelectedCategoryIndex1Depth(undefined);
        setSelectedCategoryIndex2Depth(undefined);
        setSelectedCategoryIndex3Depth(undefined);
      }
    }
  }, [categotyId1and2, categoryData, subCategoryId, subCategoryId2, searchCategoryArray]);

  useEffect(() => {
    //브랜드 or, 카테고리를 바꿀때 호출됨.
    if (categoryData.length === 0) {
      dispatch(fetchGetCategory());
    }
    _reset();
    callProductCategoriesV3(0, [], ORDER_KEY[0], keyword, searchCategory?.id, searchBrand?.id, isEOS ? '단종' : undefined);
  }, [keyword, searchCategory]); //searchBrand 도 디펜던시에 잇엇음

  const isFindBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight + 200;
  };
  const handleNavigation = (e) => {
    const isBottom = isFindBottom(e.target.body);

    if (isBottom) {
      //pagnation
      onEndReached();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  const onEndReached = async () => {
    if (
      productCount <= products.length ||
      productCount < 20 // 20개 이하는 2페이지가 없다.
    ) {
      return;
    }
    if (isLoadingRef.current) {
      return;
    }
    isLoadingRef.current = true;
    setLoading(true);
    page.current = page.current + 1;
    await callProductCategoriesV3(
      page.current,
      filterGrade,
      order,
      keyword,
      searchCategory?.id,
      searchBrand?.id,
      isEOS ? '단종' : undefined,
    );
    isLoadingRef.current = false;
    setLoading(false);
  };

  const callProductCategoriesV3 = async (
    pagination: number,
    filterGrade: string[],
    _order: string,
    _keyword?: string,
    categoryId?: number,
    brandId?: number,
    issueFilter?: string,
  ) => {
    // if (categoryData == null) return
    // if (categoryData.length <= 0) return

    if (!categoryId && !brandId) return;
    const searchValue = _keyword == null ? keyword : _keyword;
    try {
      const productData = await apiProductSearchV3(filterGrade, _order, searchValue, pagination, 20, issueFilter, categoryId, brandId);

      const data = pagination === 0 ? productData.rows : [...products, ...productData.rows];
      setProductCount(productData.count);
      setProducts((prev) => (pagination === 0 ? productData.rows : [...prev, ...productData.rows]));
    } catch (error) {}
  };

  // 등급필터 변경시 재로드
  const onPressGradeFilter = (grade: string) => {
    dispatch(
      showPopup({
        text: '등급 필터 기능은 맘가이드 앱에서만 사용하실 수 있어요:)',
        popupLastPath: '등급필터',
      }),
    );
  };

  const toggleIsBrandFilter = async () => {
    setIsBrandFilter(!isBrandFilter);
  };

  const BackHeaderTitleComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          cursor: 'pointer',
        }}
        onClick={() => {
          //   LayoutAnimation.easeInEaseOut()
          setIsVisibleProductResultCateogryMenu(!isVisibleProductResultCateogryMenu);
        }}
      >
        <span
          style={{
            // ...textStyles.headerTitle1,
            color: '#ffffff',
            fontWeight: 700,
          }}
        >
          {categoryData?.[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth!]?.name} &gt;{' '}
          {
            categoryData?.[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth!]?.children[selectedCategoryIndex2Depth!]
              ?.name
          }
        </span>
        <div
          style={{
            width: 0,
            height: 0,
            backgroundColor: 'transparent',
            borderStyle: 'solid',
            borderLeftWidth: 4.5,
            borderRightWidth: 4.5,
            borderBottomWidth: 7,
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
            borderBottomColor: '#ffffff',
            transform: 'rotate(180deg)',
            borderTopColor: 'transparent',
            marginLeft: 5,
          }}
        />
      </div>
    );
  };

  const getColor = (id) => {
    const colorData = categoryColorData.find((data) => data.id === id);
    return colorData?.color || 'var(--emerald)';
  };

  const getCategoryName = () => {
    if (selectedCategoryIndex0Depth === undefined) return <span className={styles.categoryText}>전체보기</span>;
    if (selectedCategoryIndex1Depth === undefined)
      return <span className={styles.categoryText}>{brandCategories[selectedCategoryIndex0Depth]?.name}</span>;

    if (selectedCategoryIndex3Depth !== undefined && selectedCategoryIndex2Depth !== undefined) {
      return (
        <>
          <span className={styles.categoryText}>
            {
              brandCategories[selectedCategoryIndex0Depth].children[selectedCategoryIndex1Depth]?.children?.[selectedCategoryIndex2Depth]
                ?.name
            }{' '}
            {'>'}
          </span>
          <span className={styles.categoryText}>
            {
              brandCategories[selectedCategoryIndex0Depth].children[selectedCategoryIndex1Depth]?.children?.[selectedCategoryIndex2Depth]
                ?.children?.[selectedCategoryIndex3Depth]?.name
            }
          </span>
        </>
      );
    }

    if (selectedCategoryIndex2Depth !== undefined) {
      return (
        <span className={styles.categoryText}>
          {brandCategories[selectedCategoryIndex0Depth].children[selectedCategoryIndex1Depth].name}
          {' >'}
          {
            brandCategories[selectedCategoryIndex0Depth].children[selectedCategoryIndex1Depth]?.children?.[selectedCategoryIndex2Depth]
              ?.name
          }
        </span>
      );
    }

    return (
      <span className={styles.categoryText} style={{ color: '#898989' }}>
        {brandCategories[selectedCategoryIndex0Depth].children[selectedCategoryIndex1Depth]?.name}
      </span>
    );
  };

  return (
    <AppContainerView pageTitle={category2Depth?.name}>
      <MobileTemplate>
        {selectedCategoryIndex0Depth === undefined || !(searchCategoryArray?.length > 0) ? (
          <LoadingComp />
        ) : (
          <>
            <MobileHeader
              backgroundColor={
                isBrand ? 'var(--emerald)' : getColor(categoryData[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth]?.id)
              }
              title={searchBrand?.fullName}
              titleStyle={{ color: '#ffffff' }}
              TitleComponent={isBrand ? null : <BackHeaderTitleComponent />}
              RightComponent={<SearchComponent onClick={() => navigate('/search')} color="#ffffff" />}
              arrowColor="#ffffff"
            />
            <div
              style={{
                width: '100%',
                backgroundColor: '#f0f9f7',
              }}
            >
              <div
                style={{
                  backgroundColor: '#dadada',
                  marginBottom: isBrand || category3Depth?.length === 0 ? 0 : 5,
                  display: 'grid',
                  gap: '1px',
                  gridTemplateColumns: '0.9fr 1fr 1fr',
                }}
              >
                {!isBrand &&
                  category3Depth?.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          backgroundColor: '#ffffff',
                          padding: '10px 0',
                        }}
                        onClick={() => {
                          if (item.id) {
                            navigate(
                              `/productResult/${
                                categoryData[selectedCategoryIndex0Depth]?.id +
                                ',' +
                                categoryData[selectedCategoryIndex0Depth].children[selectedCategoryIndex1Depth].id
                              }?subCategoryId=${subCategoryId}${subCategoryId === item.id ? '' : `&subCategoryId2=${item.id}`}`,
                            );
                            setSubCategoryId2(item.id);
                          }
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            color:
                              index === (selectedCategoryIndex3Depth ?? 0)
                                ? getColor(categoryData[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth].id)
                                : '#898989',
                          }}
                        >
                          {item?.name}
                        </span>
                      </div>
                    );
                  })}
              </div>
              {/* 등급별 필터링 */}

              <div className={styles.container}>
                <CategoryGradeSwich
                  //   toggleIsGrade={toggleIsGrade}
                  onPressGradeFilter={onPressGradeFilter}
                  filterGrade={filterGrade}
                  isGrade={isGrade}
                />
                <div className={styles.headerContainer}>
                  <div style={{ flexDirection: 'row' }}>
                    <SearchText productCount={productCount} isFilter={isBrand} toggleIsBrandFilter={toggleIsBrandFilter} />
                  </div>
                  <div
                    className={styles.sortFilterContainer}
                    onClick={() => {
                      //   setIsSortOpen(!isSortOpen)
                      dispatch(
                        showPopup({
                          text: `조회순, 리뷰많은순, 별점순 정렬은
                      맘가이드 앱에서만 사용하실 수 있어요:)`,
                          popupLastPath: '정렬 필터',
                        }),
                      );
                    }}
                  >
                    <img src={images.sortFilter} />
                    <span className={styles.selectedSort}>랭킹순</span>
                  </div>
                </div>

                {isBrand && (
                  <>
                    <div style={{ backgroundColor: '#f3f3f3', height: 3 }} />
                    <div
                      style={{
                        paddingLeft: 24,
                        height: 30,
                        borderBottom: 'solid 1px #f3f3f3',

                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={toggleIsBrandFilter}
                    >
                      {getCategoryName()}
                      <div
                        className={styles.triangle}
                        style={{
                          transform: `rotate(${isBrandFilter ? '0deg' : '180deg'})`,
                          marginLeft: 2,
                          marginRight: 7,
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {isBrandFilter && (
              <CategorySelectorForBrand
                style={{ top: 291, height: 'calc(100% - 291px)' }}
                //   top={headerHeight + translateY.__getValue()}
                searchBrand={searchBrand}
                brandCategories={brandCategories}
                //   navigation={navigation}
                selectedCategoryIndex0Depth={selectedCategoryIndex0Depth}
                selectedCategoryIndex1Depth={selectedCategoryIndex1Depth}
                selectedCategoryIndex2Depth={selectedCategoryIndex2Depth}
                selectedCategoryIndex3Depth={selectedCategoryIndex3Depth}
                toggleIsBrandFilter={toggleIsBrandFilter}
              />
            )}

            {products.map((item, index) => {
              return (
                <div key={item.id} style={{ backgroundColor: '#ffffff' }}>
                  <ProductItem
                    showRankingRate
                    item={item}
                    showRanking={order === 'ranking'}
                    onPressProduct={onPressProduct}
                    path="카테고리 검색"
                  />
                </div>
              );
            })}
            {isVisibleProductResultCateogryMenu && (
              <ProductResultCateogryMenu
                categoryColor={getColor(categoryData[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth]?.id)}
                selectedCategoryIndex0Depth={selectedCategoryIndex0Depth}
                selectedCategoryIndex1Depth={selectedCategoryIndex1Depth}
                setSubCategoryId={setSubCategoryId}
                selectedCategoryIndex2Depth={selectedCategoryIndex2Depth}
                setSubCategoryId2={setSubCategoryId2}
                onFinish={(mainCategoryId1, mainCategoryId2, subCategoryId) => {
                  navigate(`/productResult/${mainCategoryId1},${mainCategoryId2}?subCategoryId=${subCategoryId}`);
                  setIsVisibleProductResultCateogryMenu(!isVisibleProductResultCateogryMenu);
                  setSubCategoryId(subCategoryId);
                }}
              />
            )}
          </>
        )}
      </MobileTemplate>
    </AppContainerView>
  );
};

export default productResult;

export const Head = ({ location, params, data, pageContext }: any) => {
  // TODO: complete SEO
  // <meta name="description" content={`맘가이드 ${category2Depth?.name} 제품 목록`} />
  // <link rel="canonical" href="https://momguide.co.kr/productResult/" />

  return <SEO title="제품 목록"></SEO>;
};
