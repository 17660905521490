import React from 'react';
import { navigate } from 'gatsby-link';
import { useState } from 'react';
import * as styles from '../../styles/search/subCategoryItem2.module.css';

const Plus = ({ size = 10, borderColor, plusColor }) => {
  return (
    <div
      style={{
        display: 'flex',
        border: `solid 0.5px ${borderColor}`,
        borderRadius: 100,
        borderWidth: 0.5,
        width: size,
        height: size,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: size * 0.55,
          height: 0.8,
          backgroundColor: plusColor,
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: size * 0.55,
          height: 0.8,
          backgroundColor: plusColor,
          transform: 'rotate(90deg)',
        }}
      />
    </div>
  );
};

const Minus = ({ size = 10, borderColor, plusColor }) => {
  return (
    <div
      style={{
        display: 'flex',
        border: `solid 0.5px ${borderColor}`,
        borderRadius: 100,
        borderWidth: 0.5,
        width: size,
        height: size,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: size * 0.55,
          height: 0.8,
          backgroundColor: plusColor,
        }}
      />
    </div>
  );
};

export const SubCategoryItem2 = (props) => {
  const {
    item = {},
    index,
    find2Depth,
    selectedCategoryId2Depth,
    selectedCategoryId3Depth,
    mainCategoryItem0,
    mainCategoryItem1,
    onFinish,
    searchBrand,
  } = props;
  const hasChildren = item?.children?.length > 0;
  const [isOpen, setIsOpen] = useState(item.children.map(({ id }) => id).includes(selectedCategoryId3Depth) && hasChildren);

  const onPressSubCategory = (subCategoryId: number) => {
    if (hasChildren) {
      toggleOpen();
    } else {
      if (onFinish) {
        onFinish();
      }
      navigate(
        `/productResult/${(searchBrand ? 'brand,' : '') + mainCategoryItem0.id + ',' + mainCategoryItem1.id}?subCategoryId=${item.id}`,
        {
          state: { brand: searchBrand },
        },
      );
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.subCategoryView}>
      <div
        className={styles.subCategoryTextView}
        onClick={(e) => {
          e.stopPropagation();
          onPressSubCategory(item.id);
        }}
      >
        <span className={isOpen ? styles.subCategoryOpenText : styles.subCategoryCloseText}>{item.name}</span>
        {hasChildren ? (
          !isOpen ? (
            <Plus size={10} borderColor={'#c4c4c4'} plusColor={'#c4c4c4'} />
          ) : (
            <Minus size={10} borderColor={'#c4c4c4'} plusColor={'#c4c4c4'} />
          )
        ) : null}
      </div>
      <div className={isOpen ? styles.subCategrySubItemView : styles.subCategrySubItemViewClose}>
        {item?.children.map((data, childrenIndex) => {
          return (
            <div
              key={data.id}
              onClick={() => {
                navigate(
                  `/productResult/${(searchBrand ? 'brand,' : '') + mainCategoryItem0?.id + ',' + mainCategoryItem1.id}?subCategoryId=${item.id}${
                    item.id === data.id ? '' : '&subCategoryId2=' + data.id
                  }`,
                  {
                    state: { brand: searchBrand },
                  },
                );
              }}
            >
              <div
                style={{
                  height: 35,
                  justifyContent: 'center',
                  flex: 1,
                  paddingLeft: 36,
                  paddingTop: 10,
                  cursor: 'pointer',
                }}
              >
                <span
                  style={{
                    fontSize: 12,
                    color: selectedCategoryId3Depth === data.id ? '#000000' : '#898989',
                  }}
                >
                  {data.name}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
