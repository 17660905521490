// extracted by mini-css-extract-plugin
export var active = "subCategoryItem2-module--active--2ef91";
export var arrowDown = "subCategoryItem2-module--arrowDown--def96";
export var arrowUp = "subCategoryItem2-module--arrowUp--5f696";
export var bold = "subCategoryItem2-module--bold--1f68c";
export var categoryText = "subCategoryItem2-module--categoryText--1ab6d";
export var categoryTextView = "subCategoryItem2-module--categoryTextView--2daa3";
export var categoryUl = "subCategoryItem2-module--categoryUl--10b09";
export var clearButton = "subCategoryItem2-module--clearButton--90c5a";
export var inactive = "subCategoryItem2-module--inactive--be2a5";
export var inputBox = "subCategoryItem2-module--inputBox--7cece";
export var inputContainer = "subCategoryItem2-module--inputContainer--03dca";
export var logo = "subCategoryItem2-module--logo--4f240";
export var menuBar = "subCategoryItem2-module--menuBar--ff3ab";
export var mobile = "subCategoryItem2-module--mobile--1d7bd";
export var petActive = "subCategoryItem2-module--petActive--08d31";
export var searchLogo = "subCategoryItem2-module--searchLogo--94400";
export var searchTextbold = "subCategoryItem2-module--searchTextbold--bd104";
export var subCategoryCloseText = "subCategoryItem2-module--subCategoryCloseText--a305b";
export var subCategoryOpenText = "subCategoryItem2-module--subCategoryOpenText--a680e";
export var subCategoryTextView = "subCategoryItem2-module--subCategoryTextView--ecbcb";
export var subCategoryUl = "subCategoryItem2-module--subCategoryUl--6145b";
export var subCategoryView = "subCategoryItem2-module--subCategoryView--a60d5";
export var subCategrySubItemView = "subCategoryItem2-module--subCategrySubItemView--fa579";
export var subCategrySubItemViewClose = "subCategoryItem2-module--subCategrySubItemViewClose--b8392";
export var tabs = "subCategoryItem2-module--tabs--e21ac";
export var webHeader = "subCategoryItem2-module--webHeader--32694";