import React from 'react';

export const SearchComponent = (props) => {
  const { color = '#898989', onClick, width, height, style } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M17.8863 18.4532L14.2537 13.8138C15.8066 12.5828 16.8285 10.8029 17.1091 8.84062C17.3896 6.87833 16.9074 4.88312 15.7618 3.26591C14.6161 1.64869 12.8942 0.532612 10.9508 0.147543C9.00737 -0.237526 6.99034 0.137736 5.31514 1.19604C3.63994 2.25433 2.43414 3.91508 1.94608 5.83625C1.45801 7.75741 1.72484 9.7927 2.69161 11.5229C3.65839 13.2531 5.25149 14.5465 7.14281 15.1367C9.03413 15.727 11.0797 15.569 12.8581 14.6955L16.595 19.465C16.6719 19.5631 16.7701 19.6423 16.8821 19.6968C16.9942 19.7512 17.1172 19.7794 17.2418 19.7792C17.3954 19.7788 17.5457 19.7354 17.6758 19.6537C17.8059 19.5721 17.9105 19.4556 17.9776 19.3174C18.0448 19.1792 18.0718 19.025 18.0557 18.8722C18.0395 18.7194 17.9808 18.5742 17.8863 18.4532ZM3.29734 7.69952C3.29734 6.48374 3.65767 5.29526 4.33275 4.28437C5.00784 3.27348 5.96735 2.48559 7.08998 2.02033C8.21261 1.55507 9.44792 1.43334 10.6397 1.67053C11.8315 1.90772 12.9262 2.49317 13.7854 3.35286C14.6446 4.21255 15.2298 5.30786 15.4668 6.50028C15.7039 7.69271 15.5822 8.92869 15.1172 10.0519C14.6522 11.1752 13.8647 12.1352 12.8544 12.8107C11.8441 13.4861 10.6562 13.8466 9.4411 13.8466C7.81224 13.8448 6.25063 13.1965 5.09886 12.0441C3.94708 10.8917 3.2992 9.32927 3.29734 7.69952Z"
        fill={color}
      />
    </svg>
  );
};
