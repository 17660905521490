import React from 'react';
interface SearchTextProps {
  productCount: number;
  isFilter?: boolean;
  toggleIsBrandFilter: () => void;
}

/***
 * 키워드 검색 일 경우 ( 검색결과 n개) 로 표기.
 * 카테고리 + 서브카테고리 + 필터 조합으로 검색시 (서브카테고리(전체 -> 카테고리명) 00개)로 표기.
 */
const SearchText = ({ productCount, isFilter, toggleIsBrandFilter }: SearchTextProps) => {
  return (
    <div style={{ flexDirection: 'row', alignItems: 'center' }}>
      <span style={{ fontSize: 13, color: '#000000' }}>{productCount + '개 제품'}</span>
    </div>
  );
};

export default SearchText;
